<template>
  <div class="about">
    <h1>FFMPG Test page</h1>
    <div>
      <video :src="video" controls />
      <br />
      Video:<input type="file" id="uploader" />

      Audio:<input type="file" id="aud_uploader" />
      <br />
      <button @click="transcode">Start</button>
    </div>
    <p>{{ message }}</p>
  </div>
</template>

<script>
import { FFmpeg } from "@ffmpeg/ffmpeg";
//import type { LogEvent } from '@ffmpeg/ffmpeg/dist/esm/types'
//import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { fetchFile } from "@ffmpeg/util";
import { defineComponent, ref } from "vue";

//const baseURL = "https://unpkg.com/@ffmpeg/core-mt@0.12.4/dist/esm";
const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd";
//const videoURL = "https://ffmpegwasm.netlify.app/video/video-15s.avi";
//const videoURL = "/src/assets/test002.avi";

export default defineComponent({
  name: "App",
  setup() {
    const ffmpeg = new FFmpeg();
    const message = ref("Click Start to Transcode");
    let video = ref("");
    console.log("ASW 00000");

    //const elm = document.getElementById("uploader");
    //elm.addEventListener("change", transcode);

    async function transcode() {
      message.value = "Loading ffmpeg-core.js";
      ffmpeg.on("log", ({ message: msg }) => {
        message.value = msg;
      });
      ffmpeg.on("progress", ({ progress, time }) => {
        //message.value = `${progress * 100} %, time: ${time / 1000000} s`;
        console.log(`${progress * 100} %, time: ${time / 1000000} s`);
      });
      console.log("ASW 00000a");
      const elm = this.$el.querySelector("#uploader");
      console.log("ASW 00000a elm:", elm);
      let files = elm.files;
      console.log("ASW 00000a files:", files);

      const aud_elm = this.$el.querySelector("#aud_uploader");
      console.log("ASW 00000a aud_elm:", aud_elm);
      let aud_files = aud_elm.files;
      console.log("ASW 00000a aud_files:", aud_files);

      /*
      await ffmpeg.load({
        coreURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.js`,
          "text/javascript"
        ),
        wasmURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.wasm`,
          "application/wasm"
        ),
        workerURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.worker.js`,
          "text/javascript"
        ),
      });
	  */
      await ffmpeg.load({
        coreURL: `${baseURL}/ffmpeg-core.js`,
        wasmURL: `${baseURL}/ffmpeg-core.wasm`,
        workerURL: `${baseURL}/ffmpeg-core.worker.js`,
      });

      console.log("ASW 00000b");

      const srcFileName = files[0].name;
      console.log("files[0].name:" + files[0].name);
      const aud_srcFileName = aud_files[0].name;
      console.log("aud_files[0].name:" + aud_files[0].name);

      let msg =
        "Start transcoding [" + srcFileName + "] [" + aud_srcFileName + "]";
      console.log("msg:" + msg);
      message.value = msg;
      await ffmpeg.writeFile(srcFileName, await fetchFile(files[0]));
      await ffmpeg.writeFile(aud_srcFileName, await fetchFile(aud_files[0]));
      //await ffmpeg.writeFile("test002.avi", videoURL);
      console.log("ASW 00000c");
      await ffmpeg.exec([
        "-i",
        srcFileName,
        "-i",
        aud_srcFileName,
        "-c:v",
        "copy",
        "-c:a",
        "mp3",
        "-map",
        "0:v:0",
        "-map",
        "1:a:0",
        "test002_OUT.mp4",
      ]);
      console.log("ASW 00001");
      message.value = "Complete transcoding";
      const data = await ffmpeg.readFile("test002_OUT.mp4");
      console.log("ASW 00002");
      video.value = URL.createObjectURL(
        new Blob([data.buffer], { type: "video/mp4" })
      );
      console.log("ASW 00003");
    }
    return {
      video,
      message,
      transcode,
    };
  },
});
</script>

<style scoped>
html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
