<template>
  <div
    v-if="dataReady"
    class="dlg_c_0"
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    "
  >
    Thanks for registration !
  </div>
  <div v-else>...</div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "postRegistration",
  components: {},
  props: [],
  data() {
    return {
      dataReady: true,
    };
  },
  created() {
    this.dataReady = true;
  },
  mounted() {
    this.$_log("postRegistration 00");
    //this.$posthog.capture("asw_pageview", { fullPath: "/postReg" });
    setTimeout(() => {
      this.$_log("postRegistration 01");
      this.$router.push({ path: "/login/" });
    }, 2000);
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "currentClientId",
    ]),
  },
};
</script>
<style scoped></style>
