<template>
  <label :for="uuid" v-if="label">
    <input
      type="radio"
      :checked="modelValue === value"
      :value="value"
      @change="$emit('update:modelValue', value)"
      v-bind="$attrs"
      class="filed"
      :id="uuid"
    />
    {{ label }}</label
  >
</template>
<script>
import UniqueID from "@/features/UniqueID";

// how to call :
//	<BaseRadio v-model="variableA" :value="1" label="variableLabelA" name="variableNameInTheGroup"/>
//	<BaseRadio v-model="variableA" :value="0" label="variableLabelB" name="variableNameInTheGroup"/>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    value: {
      type: [String, Number],
      required: true,
    },
  },
  setup() {
    const uuid = UniqueID().getID();
    return { uuid };
  },
};
</script>

<style scoped>
input[type="radio"] {
  width: 24px;
  height: 24px;
  accent-color: var(--main-blue-color);
  vertical-align: text-bottom;
}
</style>
