<template>
  <div class="mc2">
    <div
      v-if="dataReady"
      class="dlg_c_0"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
      "
    >
      Thanks for your order, {{ currentUsername }} !
    </div>
    <div v-else>...</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import stripeSrv from "@/services/stripeService.js"; // access to the APIs

export default {
  name: "userOrderSuccess",
  components: {},
  props: ["sessionId"],
  data() {
    return {
      dataReady: false,
    };
  },
  created() {
    this.getData((err, r) => {
      if (err) {
        this.$_log("error:", err);
      } else {
        this.$_log("r:", r);
      }
    });
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "currentClientId",
    ]),
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("userModule", { setSubscription: "setUserSubscription" }),

    getData(callback) {
      this.$_log("user order was compleated....  getData...");
      callback(null, "ok");

      stripeSrv
        .userOrderSuccess(this.sessionId)
        .then((r) => {
          this.$_log("OOOOOOOK :) ");
          this.$_log("data:", r.data);
          this.$_log("response:", r.data.response);
          this.$_log("stripeSubscription:", r.data.response.stripeSubscription);
          this.setSubscription(r.data.response.stripeSubscription)
            .then(() => {
              this.$_log("subskrybcja zapisana ... :) ");
              this.dataReady = true;
              setTimeout(() => {
                this.$router.push(
                  //{ name: "home" }
                  //{ path: "/msSubscriptionsView/" + this.currentClientId }
                  { path: "/userSubscriptions/" }
                );
              }, 3000);
            })
            .catch((error) => {
              var n;

              this.$_log("asw error:", error);
              n = {
                type: "problem",
                message: "error:" + error,
              };
              this.addNotificationMessage(n);
            });
        })
        .catch((error) => {
          var n;

          this.$_log("asw error:", error);
          n = {
            type: "problem",
            message: "error:" + error,
          };
          this.addNotificationMessage(n);
        });
    },
  },
};
</script>

<style scoped>
.dlg_c_0 {
  /*position: relative;*/
  text-align: center;
}
</style>
