var config = (module.exports = {});

config.api_host = "https://api.muzaic.biz/mbas_prod"; // Production system

config.passwordRequirement =
  "regex:/^(?=.*[a-zA-Z0-9])(?=.*[\\!\\@\\#\\$%\\^&\\*])(?=.{8,})/";
config.passwordRequirementMsg =
  "The password must contain at least 8 characters (letters or digits) and at least 1 special character.";

config.UniversalSocialauth_idProviders = {
  apple: {
    enabled: true,
    nonce: "asw01",
    state: "00",
    clientId: "com.soundscapes.studio.signin", //
    responseMode: "query", // asw this doesnt work responseMode: "form_post",
    scope: [], // asw this scope require responseMode: "form_post" which doesnt work ...
    response_type: "code", //response_type: "code  id_token",
    optionalUrlParams: ["response_mode", "use_popup", "state"], // ["response_mode", "use_popup", "state"]
    usePopup: true,
    redirectUri: "https://app.muzaic.studio/login/apple/callback",
  },
  google: {
    enabled: true,
    clientId:
      "739828669732-aafjqs0iugjsajj5trbkrcoqn7k8oubj.apps.googleusercontent.com", // prod app 'Muzaic'
    //clientId: "787271336559-gj2g3207ob2gs2qg7tan0fanhvj41v60.apps.googleusercontent.com", // test app 'asw_MuzaicStudio'

    redirectUri: "https://app.muzaic.studio/login/google/callback",
    //redirectUri: "https://gui.muzaic.studio/login/google/callback", //test
    //redirectUri: "http://localhost:8082/login/google/callback",	//devel
  },
  facebook: {
    enabled: true,
    clientId: "1523315325139573", //FB AppId==clientId
    //clientId: "285462787490831", // asw app

    redirectUri: "https://app.muzaic.studio/login/facebook/callback/", // production
    //redirectUri: "https://gui.muzaic.studio/login/facebook/callback/", // devel
    //redirectUri: "http://localhost:8092/login/facebook/callback/",	// test
  },
};
