<template>
  <div class="objListHeader">
    <div class="objListHeaderTtile">
      <div><h2 class="titleH2">My Music</h2></div>

      <div v-if="showTailsViewButton" class="viewIcon">
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
          <rect x="16" y="16" width="9" height="9" fill="#919EAB"></rect>
          <rect y="16" width="9" height="9" fill="#919EAB"></rect>
          <rect width="9" height="9" fill="#919EAB"></rect>
          <rect x="16" width="9" height="9" fill="#919EAB"></rect>
        </svg>
      </div>
    </div>

    <div v-if="showHeaderActions != 0" class="objListHeaderActions">
      <div class="newElem">
        <button
          class="tabHeaderButton"
          @click="showAddNewDialog($event, pLinkName)"
        >
          add new
        </button>
      </div>

      <div class="searchBar">
        <template v-for="(f, key, index) in searchVars" :key="index">
          <template v-if="f.showInSearchBar === true">
            <BaseSelectDB
              v-if="f.type == 'fk_int'"
              :listLabel="f.label"
              :srcTabName="f.dataSrc"
              :elName="key"
              :selected="f.value"
              :elSize="f.elSize ? f.elSize : 1"
              :elStyle="f.elStyle != '' ? f.elStyle : ''"
              @selectUpdated="onInfoFromSelect"
              :key="f.value"
            />

            <BaseInput
              v-else
              v-model="f.value"
              :label="f.label"
              :type="f.type"
            />
          </template>
        </template>
        <button class="tabHeaderButton" @click="clearForm()">clear</button>
        <button class="tabHeaderButton" @click="emitSearch()">search</button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/baseFormElements/BaseInput.vue";
import BaseSelectDB from "@/components/baseFormElements/BaseSelectDB.vue";

export default {
  name: "tabHeader",

  components: {
    BaseInput,
    BaseSelectDB,
  },
  props: ["pLabel", "pLinkName", "dataSrc", "searchVars", "showHeaderActions"],
  data() {
    return {
      //BaseSelectDB_Key: 0,
      showTailsViewButton: false,
      searchForm: null,
      i: 0,
    };
  },
  created() {
    this.searchForm = this.searchVars;
  },
  methods: {
    forceRerender() {
      //this.BaseSelectDB_Key += 1;
    },
    clearForm() {
      for (const [k, v] of Object.entries(this.searchForm)) {
        this.$_log(k + "---");
        this.$_log(v);

        if (v.type == "fk_int") {
          this.$_log(this.$el.querySelector("." + k));

          this.$_log(this.$el.querySelector("." + k).selectedIndex);
          //this.$el.querySelector("." + k).selectedIndex = 0;
          //document.getElementsByClassName(k)[0].selected = undefined;
          this.$el.querySelector("." + k).selected = "";
          this.$_log(
            "==================================>>" +
              document.getElementsByClassName(k)[0].selectedIndex +
              "---" +
              this.$el.querySelector("." + k).selectedIndex
          );

          v.value = "";
          //this.forceRerender();
        } else {
          v.value = null;
        }
      }
    },
    onInfoFromSelect(v) {
      this.$_log("onInfoFromSelect v.name:" + v.name + " v.value:" + v.value);
      for (const [key, val] of Object.entries(this.searchForm)) {
        this.$_log(`${key}: ${val}`);
        if (key == v.name) {
          val.value = v.value;
        }
      }
    },
    showAddNewDialog(event, link) {
      this.$_log(event.target.name + "" + event.target.value + " link:" + link);

      this.$router.push({
        name: link,
      });
    },
    emitSearch() {
      this.$_log("this.searchVars::", this.searchForm);

      this.$emit("searchValues", this.searchForm);
    },
  },
};
</script>

<style scoped>
.objListHeader {
  max-width: var(--mm-max-width);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.objListHeaderTtile {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 70px;

  left: 50%;
  /*position: absolute;
  transform: translate(-50%, 0px);*/

  /*border: 1px solid red;*/
}

.objListHeaderActions {
  position: absolute;

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 80%;
  height: 70px;
  left: 50%;
  transform: translate(-50%, 70px);

  /*border: 1px solid green;*/
}
.newElem {
  display: inline-block;
}
.searchBar {
  display: inline-block;
}
.tabHeaderButton {
  background: var(--main-blue-color) 0% 0% no-repeat padding-box;
  width: 110px;
  height: 40px;
  border: double;
  border-radius: 9px;
  color: white;

  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.titleH2 {
  margin: 0px;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 0px;
  /*font-family: Poppins;*/
}

.viewIcon {
  padding: 8px;
  /*translate: 50% 0%;*/
  /*border: 1px solid #ccc;*/
  border-radius: 100%;
  background-color: #f5f5f5;
}
</style>
