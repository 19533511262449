<template>
  <nav class="user_prof_nav">
    <dropdownMenu
      name="userProfile"
      :title="title"
      :items="UserProfileServices"
      activeElement="avatar"
    />
  </nav>
</template>

<script>
import dropdownMenu from "@/components/navigation/DropdownMenu_Layout.vue";
import { mapGetters } from "vuex";

export default {
  name: "UserProfileNavBar",
  props: { title: String },
  data() {
    return {
      UserProfileServices: [
        {
          title: "Compose",
          link: "createMusicHeaderView",
          icon: "note_wb.png",
          displayAtWideScreen: false,
          fun: () => {
            this.$_log("ASW lets display current user subscriptions ...");

            this.$router.push({
              name: "createMusicHeaderView",
            });
          },
        },
        {
          title: "My Music",
          link: "userCreationsView",
          icon: "note_list_wb.png", //"Note.svg",
          displayAtWideScreen: false,
          fun: () => {
            this.$_log("ASW lets display current user subscriptions ...");

            this.$router.push({
              name: "userCreationsView",
              params: {
                pageNr: 0,
                ClientId: Number(this.currentClientId),
              },
            });
          },
        },
        {
          title: "Buy", //Subscriptions
          link: "msSubscriptionsView",
          icon: "doc.png",
          fun: () => {
            this.$router.push({
              name: "msSubscriptionsView",
              //params: { id: Number(this.currentUserId) },
              params: { id: 0 },
            });
          },
        },
        /*
        {
          title: "My Subscriptions",
          link: "userSubscriptionsView",
          icon: "doc.png", //"Subscriptions.svg",
          displayAtWideScreen: false,
          fun: () => {
            //this.$_log("ASW lets display current user subscriptions ...");

            this.$router.push({
              name: "userSubscriptionsView",
              params: { ClientId: Number(this.currentClientId) },
            });
          },
        },
		*/
        // ==>
        /*
        {
          title: "change password",
          link: "changePass",
          icon: "lock-keyhole_w.svg",
		  displayAtWideScreen: false,
          fun: () => {
            this.$_log("ASW lets change user password");

            this.$router.push({
              name: "resetPassword_UserLogedIn",
              params: { token: this.userToken },
            });
          },
        },
		*/
        // ==<
        {
          title: "Settings",
          link: "userSettings",
          icon: "settings.png", //"Settings.svg",
          displayAtWideScreen: true,
          fun: () => {
            this.$_log("ASW lets display current user settings ...");

            this.$router.push({
              name: "userSettingsView",
              params: { UserId: Number(this.currentUserId) },
            });
          },
        },
        {
          title: "Log Out",
          link: "logout",
          icon: "logout.png", //"Logout.svg",
          displayAtWideScreen: true,
          fun: () => {
            this.$_log("ASW lets logout!");

            this.$store
              .dispatch("userModule/logout")
              .then(() => {
                this.$router.push({
                  name: "login",
                  replace: true,
                });
              })
              .catch((error) => {
                this.$_log(error);
              });
          },
        },
      ],
    };
  },
  components: {
    dropdownMenu,
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "userType",
      "currentClientId",
      "currentClientFN",
      "currentClientLN",
    ]),
  },
};
</script>

<style scoped>
.user_prof_nav {
  display: flex;
  color: var(--main-gray900-color);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
