<template>
  <div class="loadingInner">
    <div class="spinner">
      <div class="spinner1">
        <div style="width: 200px; height: 200px">
          <svg
            stroke="#60eed0"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g class="spinner_V8m1">
              <circle
                cx="12"
                cy="12"
                r="9.5"
                fill="none"
                stroke-width="1"
              ></circle>
            </g>
          </svg>
        </div>
        <div class="spinnerText">{{ msg }}</div>
      </div>
    </div>
    <div v-if="bottomMsg != ''" class="loaderFooterText">{{ bottomMsg }}</div>
  </div>
</template>

<script>
export default {
  name: "loaderComponent",
  props: {
    msg: String,
    bottomMsg: String,
  },
  data() {
    return {
      dataReady: true,
    };
  },
};
</script>

<style>
.loadingInner > .loaderFooterText {
  height: 100px;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--main-bg-color);

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.spinner {
  color: var(--main-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinnerText {
  color: var(--main-bg-color);

  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
}

.spinner1 > svg {
  stroke: var(--main-secLight-color);
}

.spinner_V8m1 {
  transform-origin: center;
  animation: spinner_zKoa 2s linear infinite;

  width: 200px;
  height: 200px;
  stroke: var(--main-secLight-color);
}
.spinner_V8m1 circle {
  stroke-linecap: round;
  animation: spinner_YpZS 1.5s ease-in-out infinite;
}
@keyframes spinner_zKoa {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner_YpZS {
  0% {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0;
  }
  47.5% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -16;
  }
  95%,
  100% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -59;
  }
}
</style>
