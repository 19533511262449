<template>
  <div ref="root_cmp_fp">
    <div class="cmprDetailsPlayer">
      <div>
        <div v-if="this.videoImage == ''" id="img_container">
          <trackIcon :activeTrack="receivedPlaylistsFlag" />
        </div>

        <div
          v-else
          id="img_container"
          :style="
            'background-image: url(' +
            getBase64Img(this.videoImage) +
            ');' +
            'background-position-x: center;' +
            'width: 64px;' +
            'height: 64px; ' +
            '-webkit-clip-path: polygon(calc(50% - 32px) 0,calc(50% - 32px) 100%,calc(50% + 32px) 100%,calc(50% + 32px) 0); ' +
            'clip-path: polygon(calc(50% - 32px) 0,calc(50% - 32px) 100%,calc(50% + 32px) 100%,calc(50% + 32px) 0); ' +
            ''
          "
        >
          <!-- <img :src="getImageUrl('simplePlayW.svg')" /> -->
        </div>

        <div id="imgc_txt">
          <Popper arrow :hover="true" :content="trackName">
            <div class="truncate130px">{{ trackName }}</div>
          </Popper>
        </div>
      </div>

      <div>
        <aswAudioPlayer
          v-if="receivedPlaylistsFlag"
          id="mainPlayer"
          :audioList="audioList"
          :playerVolume="prepareVolume(volumeSlider)"
          @playClicked="handlePlayClicked"
          @trackEnds="handleTrackEnds"
          @trackPause="handleTrackPause"
          @progressDragged="handleProgressDragged"
          @playerInitialized="handlePlayerInitialized"
          playerStyle="wb"
          playerType="normalPlayer"
          @componentInterface="getChildInterface"
        />
        <div v-else id="playerInactive">
          <div id="playInactive"></div>
          <div id="playDetailsInactive">
            <div id="timeStartInactive">--:--:--</div>
            <div id="progressInactive"></div>
            <div id="timeEndInactive">--:--:--</div>
          </div>
        </div>
      </div>
      <div class="trackVolumeController">
        <template v-if="receivedPlaylistsFlag">
          <svg
            id="trackVolumeIco"
            v-if="audioVolume != 0"
            width="28"
            height="24"
            viewBox="0 0 28 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="toggleMute()"
            class="volumeIco"
          >
            <g clip-path="url(#clip0_1775_1000)">
              <path
                d="M24.2747 4.29013C24.1663 4.19722 24.0376 4.12352 23.896 4.07323C23.7544 4.02295 23.6026 3.99707 23.4493 3.99707C23.296 3.99707 23.1442 4.02295 23.0026 4.07323C22.861 4.12352 22.7323 4.19722 22.6239 4.29013C22.5155 4.38304 22.4295 4.49334 22.3708 4.61473C22.3122 4.73612 22.282 4.86623 22.282 4.99763C22.282 5.12902 22.3122 5.25913 22.3708 5.38052C22.4295 5.50192 22.5155 5.61222 22.6239 5.70513C24.5687 7.37603 25.6609 9.6399 25.6609 12.0001C25.6609 14.3604 24.5687 16.6242 22.6239 18.2951C22.405 18.4828 22.282 18.7373 22.282 19.0026C22.282 19.268 22.405 19.5225 22.6239 19.7101C22.8428 19.8978 23.1397 20.0032 23.4493 20.0032C23.7589 20.0032 24.0558 19.8978 24.2747 19.7101C26.6568 17.6637 27.9946 14.8909 27.9946 12.0001C27.9946 9.10932 26.6568 6.33656 24.2747 4.29013Z"
                fill="white"
              />
              <path
                d="M21.1166 7.29092C21.0082 7.19768 20.8795 7.12366 20.7377 7.07307C20.5959 7.02248 20.4439 6.99633 20.2903 6.9961C20.1368 6.99586 19.9847 7.02156 19.8427 7.07172C19.7007 7.12187 19.5716 7.19551 19.4629 7.28842C19.3541 7.38133 19.2677 7.49169 19.2087 7.61321C19.1497 7.73473 19.1192 7.86502 19.1189 7.99665C19.1186 8.12828 19.1486 8.25866 19.2071 8.38036C19.2656 8.50206 19.3515 8.61268 19.4599 8.70592C20.4781 9.58001 21.05 10.7647 21.05 11.9999C21.05 13.2351 20.4781 14.4198 19.4599 15.2939C19.3515 15.3872 19.2656 15.4978 19.2071 15.6195C19.1486 15.7412 19.1186 15.8716 19.1189 16.0032C19.1192 16.1348 19.1497 16.2651 19.2087 16.3866C19.2677 16.5081 19.3541 16.6185 19.4629 16.7114C19.6825 16.8991 19.9802 17.0042 20.2903 17.0037C20.4439 17.0035 20.5959 16.9774 20.7377 16.9268C20.8795 16.8762 21.0082 16.8022 21.1166 16.7089C22.5718 15.4592 23.3891 13.7656 23.3891 11.9999C23.3891 10.2342 22.5718 8.54066 21.1166 7.29092Z"
                fill="white"
              />
              <path
                d="M16.1233 0.200111C12.5759 0.771379 9.42046 2.48996 7.31033 5.00011H5.83333C4.287 5.00223 2.8047 5.52969 1.71127 6.46691C0.617842 7.40414 0.00246822 8.67468 0 10.0001L0 14.0001C0.00246822 15.3255 0.617842 16.5961 1.71127 17.5333C2.8047 18.4705 4.287 18.998 5.83333 19.0001H7.31033C9.42088 21.5099 12.5761 23.2284 16.1233 23.8001C16.1929 23.8118 16.2636 23.8175 16.3345 23.8171C16.6439 23.8171 16.9407 23.7118 17.1595 23.5242C17.3783 23.3367 17.5012 23.0823 17.5012 22.8171V1.18611C17.5016 1.03925 17.4643 0.894101 17.3919 0.760993C17.3195 0.627886 17.2137 0.510084 17.0822 0.415959C16.9507 0.321834 16.7966 0.253698 16.6308 0.216392C16.4651 0.179087 16.2918 0.173528 16.1233 0.200111ZM15.1667 21.5351C12.5934 20.832 10.3809 19.3914 8.9005 17.4551C8.79473 17.3156 8.65004 17.201 8.47953 17.1215C8.30902 17.0421 8.11808 17.0003 7.924 17.0001H5.83333C4.90508 17.0001 4.01484 16.684 3.35846 16.1214C2.70208 15.5588 2.33333 14.7958 2.33333 14.0001V10.0001C2.33333 9.20446 2.70208 8.4414 3.35846 7.87879C4.01484 7.31618 4.90508 7.00011 5.83333 7.00011H7.93333C8.1272 7.00009 8.318 6.95866 8.48849 6.87957C8.65898 6.80048 8.80379 6.68621 8.90983 6.54711C10.3872 4.61135 12.5964 3.17006 15.1667 2.46511V21.5351Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1775_1000">
                <rect width="28" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <img
            v-else
            src="@/assets/mute.png"
            @click="toggleMute()"
            class="volumeIco"
            width="28"
            height="24"
          />

          <div id="volumeActive">
            <Slider
              v-model="volumeSlider"
              :min="parseInt(0)"
              :max="parseInt(100)"
              :tooltips="false"
              @change="
                ($event) => {
                  updateAudioVolume($event);
                }
              "
            />
          </div>
        </template>
        <template v-else>
          <div id="muteInactive"></div>
          <div id="volumeInactive"></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Slider from "@vueform/slider";
import aswAudioPlayer from "@/components/elements/audioPlayer.vue";
import trackIcon from "@/components/elements/svg/trackIcon.vue";
import Popper from "vue3-popper";

export default {
  name: "createMusicDetails",

  props: ["videoImage", "receivedPlaylistsFlag", "trackName", "audioList"], //

  emits: [
    "componentInterface",
    "playClicked",
    "trackEnds",
    "trackPause",
    "playerError",
    "progressDragged",
    "playerInitialized",
  ],
  components: {
    Slider,
    aswAudioPlayer,
    trackIcon,
    Popper,
  },
  data() {
    return {
      trackDuration: "",
      video: "",

      currentAudioName: "",

      name: "",
      remarks: "",
      image: "",

      receivedPlaylistsObj: {},

      dataReady: false,

      ClientId: 0,

      importingData: false, // this flag triggers loading icon
      containerDom: null,

      filePrepared: false,
      playerReady: false,

      videoIsPlaying: false,
      videoFullScreen: false,

      volumeSlider: 50,
      audioVolume: null,
      mobileSize: false,
      audioPlaying: false,
      audioPlayingId: -1,
    };
  },
  childInterface: {
    pauseTrack: () => {},
    startTrack: () => {},
    rewindTrack: () => {},
  },
  setup() {
    const root_cmp_fp = ref(null);

    return {
      root_cmp_fp,
    };
  },

  created() {
    this.$_log("globalFooterPlayer created ...");
  },
  mounted() {
    this.$_log("globalFooterPlayer mounted ....");
    this.containerDom = this.root_cmp_fp;
    this.$_log(
      "globalFooterPlayer mounted .... this.root_cmp_fp",
      this.containerDom
    );
    this.emitInterface();
  },
  beforeUpdate() {
    this.$_log("globalFooterPlayer beforeUpdate ... ");
  },
  updated() {
    this.$_log(
      "globalFooterPlayer updated ... this.trackName:" + this.trackName
    );
    if (this.trackName != "") {
      this.root_cmp_fp.querySelector(
        ".cmprDetailsPlayer"
      ).style.backgroundColor = "var(--main-gray900-color)";

      this.root_cmp_fp.querySelector("#imgc_txt").style.color =
        "var(--main-bg-color)";
    }
  },
  methods: {
    // I N T E R F A C E >>
    // ---------------------------------------------------------
    emitInterface() {
      this.$_log("globalFooterPlayer emitInterface()");
      this.$emit("componentInterface", {
        stopSelectedTrack: (trId) => this.stopSelectedTrack(trId),
        pauseSelectedTrack: (trId) => this.pauseSelectedTrack(trId),
        startSelectedTrack: (trId) => this.startSelectedTrack(trId),
      });
    },

    // ---------------------------------------------------------
    stopSelectedTrack(trId) {
      this.$_log(
        "globalFooterPlayer] stopSelectedTrack(" + trId + ") if it works.... "
      );
      this.stopCurrentPlayer();
    },
    // ---------------------------------------------------------
    pauseSelectedTrack(trId) {
      this.$_log("globalFooterPlayer] pauseSelectedTrack(" + trId + ") .... ");
      this.pauseCurrentPlayer();
    },
    // ---------------------------------------------------------
    startSelectedTrack(trId) {
      this.$_log("globalFooterPlayer] startSelectedTrack(" + trId + ") .... ");
      this.startCurrentPlayer();
    },
    // I N T E R F A C E <<

    // ---------------------------------------------------------
    getChildInterface(childInterface) {
      this.$_log(
        "globalFooterPlayer] getChildInterface ... globalFooterPlayer ..."
      );
      this.$options.childInterface = childInterface;
    },

    // ---------------------------------------------------------
    // ---------------------------------------------------------
    startCurrentPlayer() {
      this.$_log(
        "globalFooterPlayer] startCurrentPlayer if it is not working ... "
      );
      if (this.audioPlaying == false) {
        this.$options.childInterface.startTrack(); // call child function <<
      }
    },
    // ---------------------------------------------------------
    stopCurrentPlayer() {
      this.$_log("globalFooterPlayer] stopCurrentPlayer if it works.... ");

      this.pauseCurrentPlayer();

      // video part -->
      this.stopRelatedVideo();
      // video part --<
    },

    // ---------------------------------------------------------
    pauseCurrentPlayer() {
      this.$_log("globalFooterPlayer] pauseCurrentPlayer if it works.... ");
      if (this.audioPlaying == true) {
        this.$_log(
          "globalFooterPlayer] pauseCurrentPlayer yes it works so im stopping it. this.audioPlayingId:" +
            this.audioPlayingId
        );
        this.$options.childInterface.pauseTrack(); // call child function <<
      }

      // video part -->
      this.pauseRelatedVideo();
      // video part --<
    },

    // ---------------------------------------------------------
    handleProgressDragged(obj) {
      //this.$_log("handleProgressDragged e:", e);

      let v = this.containerDom.querySelector("#vPlh");
      if (v != null) {
        v.currentTime = obj.currentTime;
      }
      let vFS = document.querySelector("#vPlh_fs");
      if (vFS != null) {
        vFS.currentTime = obj.currentTime;
      }

      this.$emit("progressDragged", obj);
    },

    // ---------------------------------------------------------
    handleTrackPause(obj) {
      this.$_log("handleTrackPause received obj:", obj);

      this.audioPlaying = false;
      //this.audioPlayingId = -1;
      this.$emit("trackPause", obj);
    },

    // ---------------------------------------------------------
    handleTrackEnds(obj) {
      this.$_log("handleTrackEnds received obj:", obj);

      this.audioPlaying = false;
      this.audioPlayingId = -1;
      this.$emit("trackEnds", obj);
    },

    // ---------------------------------------------------------
    handlePlayClicked(elem) {
      // stop other playing track or track with video AND register new playing track
      // TODO: !!!! check what is going on here !!!
      this.$_log("globalFooterPlayer] handlePlayClicked elem:", elem);
      if (elem.action == "play") {
        this.$_log("globalFooterPlayer] handlePlayClicked set local variables");
        this.audioPlaying = true;
        this.audioPlayingId = elem.trackId;
      } else {
        this.$_log(
          "globalFooterPlayer] handlePlayClicked but received action is NOT !!! play !!!"
        );
        this.audioPlaying = false;
        this.audioPlayingId = -1;
      }

      // VIDEO PART ==> should we stop related video ?
      if (this.videoImage != "" && elem.trackId == "mainPlayer") {
        this.$_log("globalFooterPlayer] handlePlayClicked VIDEO ============>");
        // lets play video
        let v = this.containerDom.querySelector("#vPlh");
        console.log("v:", v);
        if (v !== null && this.videoIsPlaying == false) {
          if (v.paused === false) {
            v.src = URL.createObjectURL(this.video);

            v.load();
          }
          v.volume = 0.0;
          v.play();

          if (this.videoFullScreen == true) {
            let vFS = document.querySelector("#vPlh_fs");

            if (vFS.paused === false) {
              vFS.src = URL.createObjectURL(this.video);

              vFS.load();
            }

            vFS.volume = 0.0;
            vFS.currentTime = v.currentTime;
            vFS.play();
          }

          this.videoIsPlaying = true;
        } else {
          //this.stopCurrentPlayer();
          this.$_log(
            "globalFooterPlayer] handlePlayClicked else ..... pauseCurrentPlayer "
          );
          this.pauseCurrentPlayer();
        }
        this.$_log("globalFooterPlayer] handlePlayClicked VIDEO ============<");
      }
      // VIDEO PART ==<

      this.$emit("playClicked", elem);
    },

    // --------------------------------------------------------------------
    handlePlayerInitialized(obj) {
      this.$emit("playerInitialized", obj);
      this.playerReady = true;
    },

    // --------------------------------------------------------------------
    updateAudioVolume(vol) {
      this.audioVolume = this.prepareVolume(vol);
      let audio = this.containerDom.querySelector("#pab_mainPlayer");

      this.$_log("current volume:" + audio.volume);
      this.$_log("new volume should be:" + this.audioVolume);
      audio.volume = this.audioVolume;
      this.$_log("new volume IS:" + audio.volume);
    },

    // --------------------------------------------------------------------
    prepareVolume(vol) {
      if (typeof vol == "undefined") {
        vol = "1.0";
      }
      this.$_log("prepareVolume volume:" + vol);
      let v = parseInt(vol, 10) / 100;
      if (v === 1) {
        v = "1.0";
      }
      if (v === 0) {
        v = "0.0";
      }
      let T = v.toString().split(".");
      v = T[0] + "." + T[1][0];
      return v;
    },

    // --------------------------------------------------------------------
    toggleMute() {
      let audio = this.containerDom.querySelector("#pab_mainPlayer");
      if (typeof audio.volume == "undefined") {
        audio.volume = 0.5;
      }
      this.$_log("A current volume:" + audio.volume);
      if (parseFloat(audio.volume) > 0) {
        audio.volume = this.prepareVolume(0);
        this.volumeSlider = 0;
      } else {
        audio.volume = this.prepareVolume(50);
        this.volumeSlider = 50;
      }
      this.$_log("B current volume:" + audio.volume);
      this.audioVolume = audio.volume;
    },

    // --------------------------------------------------------------------
    toggleFullScreen() {
      this.$_log("lets toggleFullScreen");

      let vFSc = document.querySelector("#videoFullScreenContainer");
      console.log("AAAAAA");
      if (this.videoFullScreen === false) {
        console.log("BBBBBB");
        vFSc.style = "display: grid";
        this.videoFullScreen = true;
        let v = this.containerDom.querySelector("#vPlh");
        let vFS = document.querySelector("#vPlh_fs");
        console.log("CCCCCC");
        if (vFS.paused === false) {
          vFS.src = URL.createObjectURL(this.video);
          vFS.load();
        }

        vFS.volume = 0.0;
        vFS.currentTime = v.currentTime;

        if (this.videoIsPlaying == true) {
          vFS.play();
        }

        //vFS.src = URL.createObjectURL(this.video);
      } else {
        this.videoFullScreen = false;
        vFSc.style = "display: none";
      }
    },

    // ---------------------------------------------------------
    // V I D E O functions =>

    // ---------------------------------------------------------
    stopRelatedVideo() {
      if (this.videoImage != "") {
        let v = this.containerDom.querySelector("#vPlh");
        if (this.videoIsPlaying != false) {
          v.currentTime = 0;

          if (this.videoFullScreen == true) {
            let vFS = document.querySelector("#vPlh_fs");
            vFS.currentTime = 0;
          }
        }
      }
    },

    // ---------------------------------------------------------
    pauseRelatedVideo() {
      if (this.videoImage != "") {
        let v = this.containerDom.querySelector("#vPlh");
        if (this.videoIsPlaying != false) {
          v.pause();

          if (this.videoFullScreen == true) {
            let vFS = document.querySelector("#vPlh_fs");
            vFS.pause();
          }
          this.videoIsPlaying = false;
        }
      }
    },
    // V I D E O functions =<
    // ---------------------------------------------------------

    // ---------------------------------------------------------
    getBase64Img(imgData) {
      return "data:image/jpeg;base64," + imgData;
    },
  },
};
</script>

<style scoped>
.cmprDetailsPlayer {
}
</style>
