import { createRouter, createWebHistory } from "vue-router";
import GStore from "@/store";
//import { mapGetters, mapActions } from "vuex";
import { vueApp } from "@/main.js";
import NProgress from "nprogress";

//import HomeView from "../views/public/HomeView.vue"; //it rather will not be used
import pageNotFoundPage from "@/views/public/pageNotFound.vue";

import LoginView from "@/views/public/LoginView.vue";

import UserRegistration from "@/views/public/RegisterUserView.vue";
import ResetPasswordRequest from "@/views/protected/authorization/ResetPasswordRequestView.vue";
import ResetPassword from "@/views/protected/authorization/ResetPasswordView.vue";
import confirmAccount from "@/views/protected/authorization/confirmAccount.vue";
import loginIdP from "@/views/loginIdP.vue";

import ErrorDisplay from "@/views/ErrorDisplay.vue";

//
import UserDashboard from "@/views/protected/user/UserDashboard.vue";
import UserSettings from "@/views/protected/user/UserSettings.vue";
import UserSubscriptions from "@/views/protected/user/UserSubscriptions.vue";

//
import UserCreations from "@/views/protected/myMusic/UserCreations.vue";
import createMusicHeader from "@/views/protected/myMusic/createMusicHeader.vue";
import createMusicDetailsView from "@/views/protected/myMusic/createMusicDetails.vue";

//
import msSubscriptions from "@/views/protected/msSubscriptions.vue";
import userOrderSuccess from "@/views/protected/user/UserOrderSuccess.vue";
import postRegistration from "@/views/protected/authorization/postRegistration.vue";

import TestView from "@/views/public/TestView.vue";
// =============================================================================
// =============================================================================
// =============================================================================

const routes = [
  {
    path: "/",
    name: "home",

    redirect: () => {
      let dastination = "";
      let P = {};
      let x = 0;
      //// this is because reddirect is triggered before "beforeEach" ....
      GStore.dispatch("userModule/tryToSetStateBasedOnLocalStorage");
      ////

      ///->
      let cid = GStore.state.userModule.currentLoggedUser.client_id;
      if (typeof cid != "undefined") {
        if (cid != "") {
          x = 1;
        }
      }
      if (x == 1) {
        dastination = "userCreationsView"; // if already logged in <<<
        P = {
          pageNr: GStore.state.streamModule.currentContext.pageNr,
          ClientId: GStore.state.userModule.currentLoggedUser.client_id,
        };
      } else {
        //dastination = "login"; // if not logged in <<<
        dastination = "register"; // if not logged in <<< because most of the used does not have an account yet
      }
      // ----
      return {
        name: dastination,
        params: P,
      };
      ///-<
    },
  },
  /* ASW 2023-11 test view */
  {
    path: "/test",
    name: "test",
    component: TestView,
    meta: { requireAuth: false, internalViewWithoutLogin: true },
  },
  /*
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( "../views/public/AboutView.vue"),
  },
  */
  // asw ==>
  {
    path: "/login",
    name: "login",
    //props: true,
    component: LoginView,
    meta: { requireAuth: false, internalViewWithoutLogin: true },
  },
  //
  {
    path: "/login/:provider/callback",
    name: "loginIdP",
    props: true,
    component: loginIdP,
    meta: { idp: true, requireAuth: false },
  },
  //
  {
    path: "/register",
    name: "register",
    component: UserRegistration,
    meta: { requireAuth: false, internalViewWithoutLogin: true },
  },
  //
  {
    path: "/postReg",
    name: "postRegistration",
    component: postRegistration,
    meta: {
      requireAuth: false,
      title: "muzaic.studio - successful registration",
    },
  },
  //
  {
    path: "/rpr",
    name: "resetPasswordRequest",
    component: ResetPasswordRequest,
    meta: { requireAuth: false, internalViewWithoutLogin: true },
  },
  {
    path: "/rp/:token",
    name: "resetPassword",
    component: ResetPassword,
    props: true,
    meta: { requireAuth: false, internalViewWithoutLogin: true },
  },

  {
    path: "/confirmAccount/:token",
    name: "confirmAccount",
    component: confirmAccount,
    props: true,
    meta: { requireAuth: false, internalViewWithoutLogin: true },
  },

  // =============================================================
  // P R O T E C T E D   R O U T E S >>>
  {
    path: "/userOrderSuccess",
    name: "userOrderSuccess",
    component: userOrderSuccess,
    props: (route) => ({ sessionId: route.query.session_id }),
    meta: {
      requireAuth: true,
      title: "muzaic.studio - successful order",
      contentSeperation: "L",
    },
  },

  {
    path: "/userdashboard/",
    name: "UserDashboard",
    component: UserDashboard,

    props: {
      id: GStore.state.userModule.currentLoggedUser.user_id,
    },

    meta: { requireAuth: true, contentSeperation: "L" },
  },
  //
  {
    path: "/msSubscriptionsView/:id",
    name: "msSubscriptionsView",
    component: msSubscriptions,
    //props: true,
    props: (route) => ({
      id: parseInt(route.params.id) || 0,
    }),
    meta: { requireAuth: true, contentSeperation: "L" },
  },
  //
  {
    path: "/createMusicHeaderView/",
    name: "createMusicHeaderView",
    component: createMusicHeader,
    props: true,
    meta: { requireAuth: true, contentSeperation: "L" },
  },
  //
  {
    path: "/createMusicDetailsView/",
    name: "createMusicDetailsView",
    component: createMusicDetailsView,
    props: true,
    meta: { requireAuth: true, contentSeperation: "S" },
  },

  // profile menu >>>
  // current loggedin user ->
  {
    path: "/userSettings/:UserId",
    name: "userSettingsView",
    component: UserSettings,
    props: true,
    meta: { requireAuth: true, contentSeperation: "L" },
  },
  //My Music -->
  {
    path: "/userCreations/:pageNr/:ClientId",
    name: "userCreationsView",
    component: UserCreations,

    props: (route) => ({
      pageNr:
        parseInt(route.params.pageNr) ||
        GStore.state.streamModule.currentContext.pageNr,
      ClientId:
        parseInt(route.params.ClientId) ||
        GStore.state.userModule.currentLoggedUser.client_id,
    }),
    meta: { requireAuth: true, contentSeperation: "L" },
  },
  //My Subscriptions -->
  {
    path: "/userSubscriptions/",
    name: "userSubscriptionsView",
    component: UserSubscriptions,
    props: true,
    meta: { requireAuth: true, contentSeperation: "L" },
  },
  // <-
  // profile menu <<<
  // P R O T E C T E D   R O U T E S <<<
  // =============================================================

  // -------------------------------------------------------------------------
  {
    path: "/error/:error",
    name: "ErrorDisplay",
    props: true,
    component: ErrorDisplay,
  },

  /* [404] anything else... */
  { path: "/:catchAll(.*)", component: pageNotFoundPage },
  /*{ path: "/:catchAll(.*)", redirect: { name: "login" } },*/
  // -------------------------------------------------------------------------
];

// ===========================================================================
// ===========================================================================
// ===========================================================================

// create router object ...
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// progress bar + authorization check etc ....
router.beforeEach(async (to, from, next) => {
  // show progress bar ....
  NProgress.start();

  vueApp.$_log("router beforeEach to:" + to.fullPath);

  vueApp.$_log(GStore.state.userModule.checkingState);
  if (to.meta.idp == true) {
    next();
  } else {
    if (GStore.state.userModule.checkingState == 0) {
      await GStore.dispatch("userModule/isUserLoggedIn_Action_P").then(
        (response) => {
          vueApp.$_log("router beforeEach response:" + response);

          if (response === false && to.meta.requireAuth) {
            const n = {
              type: "error",
              message: "Sorry you are not authorized",
            };
            GStore.dispatch("notificationModule/add", n, { root: true });
            next({ name: "login" });
          }

          if (typeof from.href != "undefined") {
            // <--- If this navigation came from a previous page.
            vueApp.$_log("Router beforeEach from.href IS defined:" + from.href);
            //return false;
            next();
          } else {
            // <--- if navigating directly
            vueApp.$_log("router beforeEach asw from.href is not defined! ");
            //return { path: "/" }; // <--- Push navigation to the root route.
            next();
          }
        },
        async (error) => {
          vueApp.$_log(
            "router beforeEach000	 error:",
            error.response.data.error
          );
          if (error.response.data.error == "not valid token") {
            vueApp.$_log(" ooook so lets logout and login again...");

            await GStore.dispatch("userModule/logout")
              .then(() => {
                vueApp.$_log(
                  " in router beforeEach just after logout .... current to:",
                  to
                );
                //return { name: "login" };

                vueApp.$_log(" ooook so lets reddirect route.... to: home");
                vueApp.$_log("===============================================");
                //next({ name: "home" });
                next({ name: "login" });
              })
              .catch((error) => {
                vueApp.$_log(" dammit ... error:", error);
              });
          }
        }
      );

      //
    } else {
      vueApp.$_log("somebody already checking user status....");
      next();
    }
  }
});

//
const DEFAULT_TITLE =
  "Muzaic Studio – AI composed soundtrack for your videos....";
router.afterEach((to, from) => {
  vueApp.$_log("router.afterEach from:" + from.path + " to:" + to.path);
  document.title = to.meta.title || DEFAULT_TITLE;

  NProgress.done();

  vueApp.$nextTick(() => {
    vueApp.$_log("router afterEach] nextTick..............................");
    vueApp.$_log("router afterEach] nextTick.... send signal to posthog...");

    vueApp.$posthog.capture("asw_pageview", {
      fullPath: to.fullPath,
    });

    //vueApp.$_log("router afterEach] nextTick... and to google ...");
    //if (typeof event != "undefined") {
    //  event("asw-pageview", {
    //    fullPath: to.fullPath,
    //  });
    //}

    vueApp.$_log("router afterEach] nextTick..............................");
  });
});
/*
router.afterEach(() => {
  // hide progress bar ....
  NProgress.done();
});
*/

export default router;
