<template>
  <div v-if="dataReady">
    <div v-if="isUserLoggedIn" class="A">
      <AdminPanel msg="dashboard" />
    </div>
    <div v-else class="A">
      <template v-if="typeof this.$route.meta.idp != 'undefined'">
        <template v-if="this.$route.meta.idp == true">
          <router-view />
        </template>
      </template>
      <template v-else>
        <template
          v-if="this.$route.name != '' && this.$route.meta.requireAuth == false"
        >
          <AdminPanel msg="" />
        </template>
        <template v-if="useCase == 'test'">
          <!-- <LandingPage00 msg="" /> -->
          <Login />
          <br />tmp only --<br />
          {{ this.$route.name }}
          <hr />
          {{ this.$route.meta.requireAuth }}
          <hr />
          [{{ this.$route.meta.idp }}--{{ typeof this.$route.meta.idp }}]
          <br />tmp only --<br />
        </template>
      </template>
    </div>
  </div>

  <!-- ASW notifications and dialog boxes -> -->
  <NotificationContainer />
  <DialogsWrapper />
  <!-- ASW notifications and dialog boxes -< -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import NotificationContainer from "@/components/notificationBar/NotificationContainer.vue";
import AdminPanel from "@/views/AdminPanel.vue";
import Login from "@/views/public/LoginView.vue";

//import LandingPage00 from "@/views/Landing00.vue";
//import loginIdP from "@/views/loginIdP.vue";

export default {
  name: "MainTemplate",
  //props: ["provider"],
  data() {
    return {
      useCase: "prod", //test|prod
      dataReady: false, // do not display template until data is ready!!
      userDeviceType: "",
    };
  },
  async created() {
    // check if user is logged in or if he have old session ...
    // additionally check if we can refresh session token and refresh it if possible
    // this action is also updating userModule state object
    this.$_log("App.vue created 0 ...");
    this.setDeviceType();
    await this.isUserLoggedIn_Action_P().then(
      (response) => {
        this.$_log(
          "App.vue] AAA. response:" +
            response +
            " this.currentUserId:" +
            this.currentUserId +
            " "
        );
        this.$_log("============================================");
        this.$_log(" this.$route.path:", this.$route.path);
        this.$_log("============================================");
        this.$_log("============================================");
        this.$_log(" this.$router.query:", this.$route.query);
        this.$_log("============================================");
        this.dataReady = true;

        if (response === true && this.$route.path == "/") {
          this.$_log(
            "App.vue] requested route was :" +
              this.$route.path +
              " but user is already logged in so ... lets jump to the dashboard."
          );

          this.$router.push({
            name: "userCreationsView",
            params: {
              pageNr: 0,
              ClientId: this.currentClientId,
            },
          });
        } else if (response === false) {
          this.$_log(
            "ASW 'there is no refresh token'... so user is still not authenticated...."
          );
        }
      },
      (error) => {
        this.$_log("App.vue] App.vue. error:", error.response.data.error);
      }
    );
    this.$_log("App.vue created 10 ...");
  },
  mounted() {
    //window.addEventListener("resize", this.ASWresizeWindowEventHandler);
  },
  umounted() {
    //window.removeEventListener("resize", this.ASWresizeWindowEventHandler);
  },

  components: {
    NotificationContainer,
    AdminPanel,

    Login,
    //LandingPage00,
    //loginIdP,
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "currentUsername",
      "currentUserId",
      "currentClientId",
    ]),
  },
  methods: {
    ...mapActions("userModule", ["isUserLoggedIn_Action_P"]),
    ASWresizeWindowEventHandler(e) {
      if (e.target.innerWidth < 850) {
        this.$_log("hmm lets resize header!");
      }
    },

    // -------------------------------------------------------------
    // rather not good idea
    setDeviceType() {
      if (
        this.isMobileUserAgent() &&
        this.hasTouchSupport() &&
        this.isMobileWidth()
      ) {
        this.userDeviceType = "mobile";
      } else {
        this.userDeviceType = "desktop";
      }
      this.$_log("===== userDeviceType:" + this.userDeviceType + " =====");
    },
    isMobileUserAgent() {
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      return regex.test(navigator.userAgent);
    },
    hasTouchSupport() {
      return "ontouchstart" in window || navigator.maxTouchPoints > 0;
    },
    isMobileWidth() {
      const minWidth = 768; // Minimum width for desktop devices
      return window.innerWidth < minWidth || screen.width < minWidth;
    },
  },
};
</script>

<style src="@/assets/css/internal_dialogform_0.css"></style>
<style>
:root {
  --main-txt-color: black;
  --main-bg-color: white;
  /* primary colors -> */
  --main-bluelighter-color: #ccdbff;
  --main-bluelight-color: #668eff;
  --main-blue-color: #0038ff;
  --main-bluedark-color: #001fb7;
  --main-bluedarker-color: #000f7a;
  /* primary colors -< */

  /* secondary colors -> */
  --main-seclighter-color: #cafce8;
  --main-seclight-color: #60eed0;
  --main-sec-color: #01c8c1;
  --main-secdark-color: #007b90;
  --main-secdarker-color: #004160;
  /* secondary colors -< */

  /* info colors -> */
  --main-infolighter-color: #66fffe;
  --main-infolight-color: #3ff0ff;
  --main-info-color: #00d8ff;
  --main-infodark-color: #00a8db;
  --main-infodarker-color: #007fb7;
  /* info colors -< */

  /* sucess colors -> */
  --main-sucesslighter-color: #c9f670;
  --main-sucesslight-color: #b1ed4c;
  --main-sucess-color: #8de216;
  --main-sucessdark-color: #70c210;
  --main-sucessdarker-color: #56a20b;
  /* sucess colors -< */

  /* warning colors -> */
  --main-warninglighter-color: #ffc878;
  --main-warninglight-color: #ffb256;
  --main-warning-color: #ff8e1e;
  --main-warningdark-color: #db6e15;
  --main-warningdarker-color: #b7520f;
  /* warning colors -< */

  /* error colors -> */
  --main-errorlighter-color: #ff8e90;
  --main-errorlight-color: #ff7281;
  --main-error-color: #ff446a;
  --main-errordark-color: #db3164;
  --main-errordarker-color: #b7225d;
  /* error colors -< */

  /* gray colors -> */
  --main-gray100-color: #f9fafb;
  --main-gray200-color: #f4f6f8;
  --main-gray300-color: #ebeff3;
  --main-gray400-color: #c4cdd5;
  --main-gray500-color: #919eab;
  --main-gray600-color: #637381;
  --main-gray700-color: #454f5b;
  --main-gray800-color: #212b36;
  --main-gray900-color: #161c24;
  /* gray colors -< */

  --main-modal-dialog-bkg: rgba(185, 187, 189, 0.5);

  --main-linearGradient-color: white;
  --main-btn-border-color: rgba(0, 56, 255, 0.5);
  --main-btn-border-color-hover: rgb(0, 56, 255);
  --internal-dialog-border-color: rgb(196, 205, 213);

  --composer-section-bg-color: rgb(244, 246, 248);

  --disabled-color: var(--main-gray300-color);
  --border-disabled-color: var(--main-gray400-color);
  --font-disabled-color: var(--main-gray500-color);

  --btn-orange: rgb(255, 142, 30);

  /* slider >> */
  --slider-bg: rgb(196, 205, 213);
  --slider-connect-bg: rgb(0, 56, 255);
  --slider-tooltip-bg: rgb(0, 56, 255);
  --slider-handle-ring-color: #3b82f630;
  --slider-height: 4px;
  /* slider << */

  /* Popper tooltips >> */
  --popper-theme-background-color: #161c24;
  --popper-theme-background-color-hover: #454f5b;
  --popper-theme-text-color: white;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 10px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  /* Popper tooltips << */

  --width-layout-switch: 640px;
  --main-scroll-width: 0px; /* 16px*/
  --main-left-margin: 26px;
  --global-left-margin: var(
    --main-scroll-width
  ); /* calc(var(--main-left-margin) + var(--main-scroll-width) ); */
  --menu-content-seperation: 0px; /* 109px */
  --menu-content-seperation-L: 68px; /* desktops views except composer */
  --menu-content-seperation-S: 40px; /* mobile views + desktop composer */

  --general-maximal-width: 1360px;
  --general-minimal-width: 300px;

  --wideTabColumn: 300px;
  --mm-max-width: 1132px;
}

body {
  margin: 0px;
}

.A {
  /* */
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.mc2 {
  height: 100%;
  max-width: var(--general-maximal-width);
  flex-grow: 1;
}

/* Vue Project defaults >>>> */
#app {
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  line-height: 16px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--main-txt-color);
  overflow-x: hidden;
}

/* Vue Project defaults <<<< */
a {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  line-height: 16px;
}
a:link {
  color: var(--main-info-color);
}
a:visited {
  color: var(--main-infodark-color);
}
a:hover {
  color: var(--main-infodark-color);
}
a:active {
  color: var(--main-info-color);
}

button {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

/* === LOGIN === */
.lgn_c0,
.dlg_c_0 {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* === TABLES WITH LIST OF DATA === > */
.tabDataContainer {
  display: flex;
  max-width: var(--mm-max-width);
  width: 100%;
  margin: 0 auto;
}

.tabDataContainer table {
  margin: 0 auto;
  text-align: left;
  font-size: 14px;
  text-decoration: none;
  letter-spacing: 0px;
  padding-left: 5px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 16px;
}

.tabDataContainer table,
th,
td {
  border: 0px solid;
  font-weight: 600;
}

.tabDataContainer table thead th {
  border-bottom: 1px solid var(--main-gray300-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  height: 40px;
}
.tabDataContainer table thead th:hover {
  background-color: none !important;
}

.tabDataContainer table tbody tr {
  background-color: #fff;
  padding-left: 5px;

  max-height: 64px;
  min-height: 64px;
}

.tabDataContainer table tbody tr.odd {
  background-color: #fff; /*rgb(231, 233, 231);*/
  padding-left: 5px;
}

.tabDataContainer table tbody tr td.odd {
  background-color: rgb(231, 233, 231);
}

.tabDataContainer table tbody tr:hover {
  background-color: #f5f5f5;
  padding-left: 5px;
}

.wideTabColumn {
  width: var(--wideTabColumn);
}

/* */
.indexColumn {
  margin: 0px 16px 0px 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;

  /*display: flex;*/
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
  width: 120px;
  height: 64px;
}

td.trackNameColumn,
td.trackDurationColumn {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.alignedContent {
  display: flex;
  align-items: center;
}
/* === TABLES WITH LIST OF DATA === < */

/* 2023-06-12 > */
.blueBtn {
  border: 1px solid var(--main-blue-color);
  background-color: var(--main-blue-color);
  padding: 10px;

  top: 50%;
  left: 50%;
  margin: 0px 0 0 0px;

  border-radius: 10px;
  box-shadow: none;

  font-size: inherit;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.blueBtn:hover {
  letter-spacing: 0px;

  border: 1px solid var(--main-bluedarker-color);
  background-color: var(--main-bluedarker-color);
  color: white;
}

.button_W {
  border: 1px solid var(--main-blue-color);
  background-color: var(--main-bg-color);
  padding: 8px 24px 8px 24px;
  margin: 0px 0px 0px 0px;
  border-radius: 8px;

  color: var(--main-blue-color);

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: none;
  justify-content: center;
  display: flex;
}

.button_W:hover {
  border: 1px solid var(--main-blue-color);
  /*background-color: var(--main-blueDarker-color);*/
  background-color: var(--main-gray200-color);
  /*color: #fff;*/
}

/* 2023-06-12 < */
.iconPlcHld_one {
  width: 64px;
  height: 64px;
  background: linear-gradient(
    90deg,
    rgb(0, 216, 255) 0%,
    rgb(96, 238, 208) 100%
  );
  /*margin-left: 30px;*/
}

.iconPlcHld {
  width: 64px;
  height: 64px;
  background: linear-gradient(
    90deg,
    rgb(0, 216, 255) 0%,
    rgb(96, 238, 208) 100%
  );
  /*margin-left: 30px;*/
  position: absolute;
}

.iconPlcHld_e {
  width: 64px;
  height: 64px;

  margin-left: 30px;
  position: absolute;
}

/* 2023-07-23 > */
input,
textarea {
  width: 100%;
  display: block;
}

input:hover + fieldset {
  border-color: rgba(0, 0, 0, 0.87);
}

input:focus + fieldset {
  border-color: var(--main-blue-color);
  border-width: 2px;
}

.action_enabled {
  cursor: pointer;
}
.cancel_action_disabled {
  color: var(--font-disabled-color);
  border-color: var(--border-disabled-color);
  box-shadow: none;
}

.submit_action_disabled {
  background-color: var(--disabled-color);
  border-color: var(--disabled-color);
  color: var(--font-disabled-color);
  box-shadow: none;
}

/* ------- */
</style>
